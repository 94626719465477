@media (max-width: 400px) {
}
/*
    This files is included after the main less, plz "hotfix" here ...
*/
body.homepage main > section > article {
  text-align: left !important;
}
body.homepage main > section > article > h1,
body.homepage main > section > article > p {
  padding-right: 20px;
  padding-left: 20px;
  text-align: left;
}
@media only screen and (max-width: 767px) {
  body.homepage main > section > article > h1,
  body.homepage main > section > article > p {
    padding-right: 10px;
    padding-left: 10px;
    text-align: left;
    line-height: 1.4;
  }
}
body.homepage main > section > article > p {
  margin-top: 10px;
}
body.homepage main > section.pull.right > article {
  top: 10%;
  padding: 0;
}
body.homepage main > section.about-section-1 > article,
body.homepage main > section.about-section-11 > article {
  text-align: center !important;
}
body.homepage main > section.about-section-2 > article {
  top: 45%;
  width: 60%;
}
@media only screen and (max-width: 767px) {
  body.homepage main > section.about-section-2 > article {
    width: auto;
  }
  body.homepage main > section.about-section-2 > article > h1 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
}
body.homepage main section.about-section-4 > article > h1 {
  top: 3%;
  padding-right: 20px;
  padding-left: 20px;
}
@media only screen and (max-width: 767px) {
  body.homepage main section.about-section-4 > article > h1 {
    margin-right: 0 !important;
  }
}
@media only screen and (max-width: 767px) {
  body.homepage main section.about-section-4 > article article.active,
  body.homepage main section.about-section-4 > article article.start,
  body.homepage main section.about-section-4 > article article.end {
    justify-content: flex-start !important;
  }
  body.homepage main section.about-section-4 > article article.active > h2,
  body.homepage main section.about-section-4 > article article.start > h2,
  body.homepage main section.about-section-4 > article article.end > h2 {
    flex-grow: 0 !important;
  }
  body.homepage main section.about-section-4 > article article.active > p,
  body.homepage main section.about-section-4 > article article.start > p,
  body.homepage main section.about-section-4 > article article.end > p {
    margin-top: 35vh !important;
  }
}
@media only screen and (max-width: 767px) {
  body.homepage main > section.about-section-5 > article {
    padding: 10px 40px 0 20px !important;
  }
  body.homepage main > section.about-section-5 > article > h1 {
    margin-top: -30px;
    padding: 10px !important;
    line-height: 1.2;
  }
  body.homepage main > section.about-section-5 > article > p {
    margin-top: 0 !important;
    padding-right: 10px !important;
  }
}
@media only screen and (max-width: 767px) {
  body.homepage main > section.about-section-6 > article > h1 {
    padding: 10px 20px !important;
  }
  body.homepage main > section.about-section-6 > article > p {
    margin-top: 0 !important;
    padding-left: 20px !important;
  }
}
body.homepage main > section.about-section-8 > article {
  padding-left: 20px !important;
}
body.homepage main > section.about-section-8 > article > h1 {
  padding-bottom: 0 !important;
}
body.homepage main > section.about-section-8 > article > p {
  padding-right: 20px !important;
  padding-left: 20px !important;
}
body.homepage main > section.about-section-9 > article {
  padding-left: 10px !important;
}
body.homepage main > section.about-section-9 > article > h1 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
body.homepage main > section.about-section-9 > article > p {
  margin-top: 0 !important;
}
body.report main > form > footer {
  display: block !important;
}
