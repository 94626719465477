@import (reference) "styles";

/*
    This files is included after the main less, plz "hotfix" here ...
*/

body.homepage main {
  & > section {
    & > article {
      text-align: left !important;

      & > h1,
      & > p {
        padding-right: 20px;
        padding-left: 20px;
        text-align: left;

        .on(@mobile, {
          padding-right: 10px;
          padding-left: 10px;
          text-align: left;
          line-height: 1.4;
        });
      }

      & > p {
        margin-top: 10px;
      }
    }
  }


  & > section.pull.right {
    & > article {
      top: 10%;
      padding: 0;
    }
  }


  & > section.about-section-1,
  & > section.about-section-11 {
    & > article {
      text-align: center !important;
    }
  }


  & > section.about-section-2 {
    & > article {
      top: 45%;
      width: 60%;

      .on(@mobile, {
        width: auto;

        & > h1 {
          padding-top: 10px !important;
          padding-bottom: 10px !important;
        }
      });
    }
  }


  section.about-section-4 {
    & > article {
      & > h1 {
        top: 3%;
        padding-right: 20px;
        padding-left: 20px;

        .on(@mobile, {
          margin-right: 0 !important;
        });
      }

      article {
        &.active,
        &.start,
        &.end {
          .on(@mobile, {
            justify-content: flex-start !important;

            & > h2 {
              flex-grow: 0 !important;
            }

            & > p {
              margin-top: 35vh !important;
            }
          });
        }
      }
    }
  }


  & > section.about-section-5 {
    & > article {

      .on(@mobile, {
        padding: 10px 40px 0 20px !important;

        & > h1 {
          margin-top: -30px;
          padding: 10px !important;
          line-height: 1.2;
        }

        & > p {
          margin-top: 0 !important;
          padding-right: 10px !important;
        }
      });
    }
  }


  & > section.about-section-6 {
    & > article {
      .on(@mobile, {
        & > h1 {
          padding: 10px 20px !important;
        }

        & > p {
          margin-top: 0 !important;
          padding-left: 20px !important;
        }
      });
    }
  }


  & > section.about-section-8 {
    & > article {
      padding-left: 20px !important;

      & > h1 {
        padding-bottom: 0 !important;
      }

      & > p {
        padding-right: 20px !important;
        padding-left: 20px !important;
      }
    }
  }


  & > section.about-section-9 {
    & > article {
      padding-left: 10px !important;

      & > h1 {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
      }

      & > p {
        margin-top: 0 !important;
      }
    }
  }

  
}

body.report main {
  & > form > footer {
    display: block !important;
  }
}