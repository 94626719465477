//region - montreal font
//Bold
@font-face {
    font-family:'Montreal';
    src: url('@{path-fonts}/montreal/MontrealTS-Bold.eot');
    src: url('@{path-fonts}/montreal/MontrealTS-Bold.eot?#iefix') format('embedded-opentype'),
    url('@{path-fonts}/montreal/MontrealTS-Bold.woff2') format('woff2'),
    url('@{path-fonts}/montreal/MontrealTS-Bold.woff') format('woff'),
    url('@{path-fonts}/montreal/MontrealTS-Bold.svg#MontrealTS-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-F64D;

}
.montreal-bold{
    font-family: @font-family-paragraph;
    font-weight: bold;
}
//Demibold
@font-face {
    font-family:'Montreal';
    src: url('@{path-fonts}/montreal/MontrealTS-DemiBold.eot');
    src: url('@{path-fonts}/montreal/MontrealTS-DemiBold.eot?#iefix') format('embedded-opentype'),
    url('@{path-fonts}/montreal/MontrealTS-DemiBold.woff2') format('woff2'),
    url('@{path-fonts}/montreal/MontrealTS-DemiBold.woff') format('woff'),
    url('@{path-fonts}/montreal/MontrealTS-DemiBold.svg#MontrealTS-DemiBold') format('svg');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-F64D;
}
.montreal-demibold{
    font-family: @font-family-paragraph;
    font-weight: 500;
}
//Regular
@font-face {
    font-family:'Montreal';
    src: url('@{path-fonts}/montreal/MontrealTS-Regular.eot');
    src: url('@{path-fonts}/montreal/MontrealTS-Regular.eot?#iefix') format('embedded-opentype'),
    url('@{path-fonts}/montreal/MontrealTS-Regular.woff2') format('woff2'),
    url('@{path-fonts}/montreal/MontrealTS-Regular.woff') format('woff'),
    url('@{path-fonts}/montreal/MontrealTS-Regular.svg#MontrealTS-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-F64D;
}
.montreal-regular{
    font-family: @font-family-paragraph;
    font-weight: normal;
}
//Light
@font-face {
    font-family:'Montreal';
    src: url('@{path-fonts}/montreal/MontrealTS-Light.eot');
    src: url('@{path-fonts}/montreal/MontrealTS-Light.eot?#iefix') format('embedded-opentype'),
    url('@{path-fonts}/montreal/MontrealTS-Light.woff2') format('woff2'),
    url('@{path-fonts}/montreal/MontrealTS-Light.woff') format('woff'),
    url('@{path-fonts}/montreal/MontrealTS-Light.svg#MontrealTS-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-F64D;
}
.montreal-light{
    font-family: @font-family-paragraph;
    font-weight: 300;
}
//endregion
//region - univers font
@font-face {
    font-family:'UniversLTCYR';
    src: url('@{path-fonts}/univers/UniversLTCYR-65Bold.eot');
    src: url('@{path-fonts}/univers/UniversLTCYR-65Bold.eot?#iefix') format('embedded-opentype'),
    url('@{path-fonts}/univers/UniversLTCYR-65Bold.woff2') format('woff2'),
    url('@{path-fonts}/univers/UniversLTCYR-65Bold.woff') format('woff'),
    url('@{path-fonts}/univers/UniversLTCYR-65Bold.svg#UniversLTCYR-65Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0-10FFFF;
}
@font-face {
    font-family:'UniversLTCYR';
    src: url('@{path-fonts}/univers/UniversLTCYR-45Light.eot');
    src: url('@{path-fonts}/univers/UniversLTCYR-45Light.eot?#iefix') format('embedded-opentype'),
    url('@{path-fonts}/univers/UniversLTCYR-45Light.woff2') format('woff2'),
    url('@{path-fonts}/univers/UniversLTCYR-45Light.woff') format('woff'),
    url('@{path-fonts}/univers/UniversLTCYR-45Light.svg#UniversLTCYR-45Light') format('svg');
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0-10FFFF;
}
//endregion
//region - icon font
@font-face {
    font-family: 'icomoon';
    src: url('@{path-fonts}/icons/icomoon.eot?7zbuf4');
    src: url('@{path-fonts}/icons/icomoon.eot?7zbuf4#iefix') format('embedded-opentype'),
         url('@{path-fonts}/icons/icomoon.ttf?7zbuf4') format('truetype'),
         url('@{path-fonts}/icons/icomoon.woff?7zbuf4') format('woff'),
         url('@{path-fonts}/icons/icomoon.svg?7zbuf4#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    //noinspection CssNoGenericFontName
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-l:before {
    content: "\e900";
}
.icon-arrow-r:before {
    content: "\e901";
}
.icon-close:before {
    content: "\e902";
}
.icon-doc:before {
    content: "\e903";
}
.icon-earth:before {
    content: "\e904";
}
.icon-edit:before {
    content: "\e905";
}
.icon-email:before {
    content: "\e906";
}
.icon-empower:before {
    content: "\e907";
}
.icon-eu:before {
    content: "\e908";
}
.icon-info:before {
    content: "\e909";
}
.icon-monitor:before {
    content: "\e90a";
}
.icon-ok:before {
    content: "\e90b";
}
.icon-phone:before {
    content: "\e90c";
}
.icon-plus:before {
    content: "\e90d";
}
.icon-scroll-down:before {
    content: "\e90e";
}
.icon-strategy:before {
    content: "\e90f";
}
.icon-sync:before {
    content: "\e910";
}
.icon-vision:before {
    content: "\e911";
}
//endregion

//region - spinner
.spinner {
    display: none;
    width: 70px;
    margin-top: 0;
    text-align: center;
   div {
        width: 10px;
        height: 10px;
        margin: 0;
        background-color: #fff;

        border-radius: 100%;
        display: inline-block;
        -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
        animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }   
    .bounce1 {
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }

    .bounce2 {
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
    }  
    @-webkit-keyframes sk-bouncedelay {
        0%, 80%, 100% { -webkit-transform: scale(0) }
        40% { -webkit-transform: scale(1.0) }
    }

    @keyframes sk-bouncedelay {
        0%, 80%, 100% { 
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% { 
            -webkit-transform: scale(1.0);
            transform: scale(1.0);
        }
    }       
}






//endregion


//region - variables
@path-fonts: '/assets/fonts';
@path-image: '/assets/images';

@font-family-paragraph: 'UniversLTCYR', Helvetica, Arial, sans-serif;
@font-family-structure: 'Montreal', Helvetica, Arial, sans-serif;
@font-size-legal: 9px;
@font-size-small:   12px;
@font-size-base:   14px;
@font-size-above:   16px;
@font-size-large:   20px;
@font-size-huge:   38px;

@color-black: #323232;
@color-white: #EBEBEB;
@color-primary: #009CDA;
@color-primary-lighter: #009ddd;
@color-primary-darker: #093C80;
@color-highlight: #e95e28;
@color-hightlight-light: #f39900;
@color-hot: #dc006b;
@color-secondary: #a6dbf2;
@color-success: #97be0d;
@color-gray-light: #767676;
@color-gray-brand: #546862;
@color-gray-dark: #323232;

@transition-fast: 0.2s;
@transition-slow: 0.5s;
//endregion

//region - responsive overview and helpers
/*

    !!!!! We are using desktop first approach !!!!!

*/
@4k:     ~"(min-width: 1921px)";
@laptop: ~"(max-width: 1440px)";
@tablet: ~"(max-width: 1024px)";
@mobile: ~"(max-width: 767px)";

//neat little helper
.on(@size, @rules) {
    @media only screen and @size { @rules(); }
}

/* Rainbow test, to enable add asterisk here->/
body:before{
    border-width: 1em;
    border-style: solid;
    padding: 2em;
    content: "ಠ▃ಠ";
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    border-color: #FF7F00;
    color: #4B0082;

    .on(@4k, {
        border-color: #FF0000;
        color: #9400D3;
    });

    .on(@laptop, {
        color: #0000FF;
        border-color: #FFFF00;
    });

    .on(@mobile, {
        color: #00FF00;
        border-color:  	#00FF00;
    })
}
/* - */
//endregion

//region - global typography
html {
    font-family: @font-family-structure;
    font-weight: normal;
    font-style: normal;
    font-size: @font-size-base;
    color: @color-black;
    letter-spacing: .04em;
    line-height: 1;

}
html,body,body > main{
    min-height: 100vh;
}
mark {
    background: none;
}
template {
    display: none !important;
}
h1, .h1 {
    font-size: @font-size-huge;
}
h2, .h2 {
    font-size: @font-size-large;
}

h1, h2, h3,
.h1, .h2, .h3 {
    color: @color-primary;
    font-weight: 500; //demibold
    font-family: @font-family-structure;
    margin-bottom: 0;
    .on(@mobile, {
        margin-top: 2em;
        font-size: 150%;
    })
}
p {
    font-family: @font-family-paragraph;
    line-height: 1.5;
    margin-bottom: 0;

    strong{
        font-weight: bold;
    }
}
//endregion

//region - click-ables like buttons and links
// anchors ...
a, button.link {
    text-decoration: none;
    background-color: transparent;
    color: @color-primary;
    outline: none;

    padding: 0;
    margin: 0;
    display: inline;
    border: none;
    box-shadow: none;

    &:active{
        color: @color-primary;
    }

    &:hover{
        color: @color-secondary;
    }
}
.indicator{
    border-width: 1px;
    border-radius: 50%;
    border-style: solid;
    border-color: @color-primary;
    height: 2.5em;
    width: 2.5em;
    padding: 0;
    font-style: normal;
    text-align: center;
    vertical-align: baseline;
    text-transform: uppercase;
    line-height: 2.5em;
    font-size: 25px;
}

//buttons looking things ...
button, .button {
    cursor: pointer;
    outline: none;
    padding: 0.5em 2em;
    background-color: transparent;
    border-radius: 0.5em;
    display: inline-block;
    margin-right: 0.5em;
    margin-left: 0.5em;

    border: 1px solid @color-primary;
    color: @color-primary;
    opacity: 1;
    transition: background-color, opacity @transition-fast ease, color @transition-fast  ease;

    &:disabled {
        filter: ~"grayscale(100%)";
        pointer-events: none;
        cursor: pointer;
    }

    &:hover:not(:disabled){
        color: white;
        background-color: @color-primary;
    }
    &.disabled {
        // opacity: 0.5;
        span {display: none}
        .spinner {display: block;}
    }
    &.neutral{
        color: @color-black;
        border-color: @color-black;
        border-radius: 1em;
        font-size: 80%;
        &:hover{
            color: @color-black;
            background-color: white;
        }
    }

    &.inverted {
        color: @color-white;
        border-color: @color-white;

        &.active {
            background-color: white;
            color: @color-primary;
        }
    }

    &.danger {
        color: @color-highlight;
        border-color: @color-highlight;
        &:hover{
            color: white;
            background-color: @color-highlight;
        }
    }

    &.success {
        color: @color-success;
        border-color: @color-success;

        &:hover{
            color: white;
            background-color: @color-success;
        }
    }

    &.hot {
        color: @color-hot;
        border-color: @color-hot;

        &:hover{
            color: white;
            background-color: @color-hot;
        }
    }
    &.warning {
        color: @color-hightlight-light;
        border-color: @color-hightlight-light;
        &:hover{
            color: white;
            background-color: @color-hightlight-light;
        }
    }
}
body.resources,
body.hub {
    .page-header {
        button:hover,
        .button:hover {
            background: #fff;
            color: @color-primary;
        }
    }
}   
button[data-action="select-all"],
button[data-action="select-all-countries"] {
    &.inverted, &:hover{
        background-color: @color-black;
        color: white;
        border-color: white;
    }
}
//endregion

//region - nav containers
// Common nav shit
//
nav {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    left: 0;
    right: 0;
    padding-left: 1em;
    padding-right: 1em;

    a {
        display: block;
        padding: 0.5em 2em;
        align-self: center;
        font-size: @font-size-above;
    }
}

//main top nav
//
nav.main {
    position: fixed;
    top: 0;
    z-index: 50;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.85);
 
    &:not(.open){
        .active {
            font-weight: bold;
        }
    }
  
    .privacy{
        display: none;
    }

    > a:first-child {
        margin-top: auto;
        margin-bottom: auto;

        .on(@tablet, {
            margin-top: 0;
            padding-left: 0;
            padding-right: 0;
        });

        > img {
            width: 10em;
        }
        > img.alt {
            display: none;
        }
    }

    > div:not(.nag) {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-content: flex-end;
        margin-top:auto;
        margin-bottom:auto;
    }

    > .menu{
        margin-left: auto;
        a {
            color: @color-black;
            &:visited {
                color: inherit;
            }
            &:hover{
                color: @color-primary;
            }

            &.active {
                color: @color-primary;
            }
        }
    }

    .on(@tablet, {
        align-items: flex-start;
        > div:not(.nag) {
            flex-grow: 2;
            text-align: center;
            flex-flow: column nowrap;
            a {
                color: @color-black;
                display: none;
                margin: .8em 2em;
            }
        }
    });

    .hamburger {
        right: 0;
        top: 0;
        align-self: flex-start;
        margin-bottom: 0;
        display: none;
        padding: 2em;
        height: 40px;
        box-shadow: none;
        outline: none;
        text-align: right;
        line-height: 1;
        box-sizing: content-box;
        position: absolute;

        &:hover, &:active{
            background-color: transparent;
            opacity: 1;
        }

        > span > span {
            background-color: @color-black;
            &:before, &:after {
                background-color: @color-black;
            }
        }

        .on(@tablet, { display: block; })
    }

    .on(@tablet,{
        &.open {
            background-color: white;
            z-index: 51;
            > a:first-child {
                position: absolute;
                top:0;
            }
            > div:not(.nag){
                height: 100vh;
                > a {
                    display: block;
                }
            }
        }
    });
}
// section nav
//
nav.secondary {
    position: absolute;
    box-sizing: border-box;
    bottom: 0;
    margin-top: 0;
    width: 100%;
    z-index: 50;
    background-color: fade(@color-primary, 80%);

    > a {
        margin: 0;
        color: @color-white;
        padding-top: 1em;
        padding-bottom: 1em;
        text-decoration: none;

        &:visited{
             color: @color-white;
        }

        &:first-child {
            margin-left: auto;
        }
        &:last-child {
            margin-right: auto;
        }

        &.active {
            color: @color-white;
            font-weight: 500;
            text-decoration: none;
        }
        &:hover {
            color: @color-white;
            text-decoration: none;
        }
    }
}

// offset card style nav
//
nav.cards {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
    padding-right: 0;
    padding-left: 0;
    max-width: 710px;
    box-sizing: border-box;

    .on(@tablet, {
        justify-content: space-around;
        width: 100%;
    });

    .on(@mobile, {
        display: block;
        min-width: 100%;
        margin-top: 0;
    });

    > a {
        box-sizing: border-box;
        min-height: 340px;
        flex: 1 0 300px;
        max-width: 300px;

        color: white;
        padding: 1em;
        text-align: left;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        background-color: rgba(0,0,0, 0.4);
        align-self: flex-start;
        margin-bottom: 2em;
        line-height: 1.2;
        margin-top: 0;
        margin-left: 1em;
        margin-right: 1em;

        .on(@mobile, {
            flex: 1 0 100%;
            margin-left: 2em;
            margin-right: 2em;
            width: auto;
            max-width: 100%;
            margin-bottom: 0;
            margin-top: 2em;
        });

        i {
            border: 2px solid white;
            display: block;
            float: left;
            margin-right: 1em;
            text-align: center;
        }
    }

    > :nth-child(even){
        .on(@mobile, {
            position: static;
        });
        margin-bottom: auto;
        position: relative;
        top: 4em;
    }

    > :last-child {
        margin-right: ~"calc(320px + 2em)";
        margin-left: 1em;
        .on(@mobile, {
            margin-right: 2em;
            margin-left: 2em;
        });
    }
}

//fixed in page dots style
//
nav.dots {
    position: fixed;
    flex-direction: column;
    left: 2em;
    width: auto;
    max-width: none;
    margin: 0 0;
    right: auto;
    top: 50%;

    .on(@tablet, {
        display: none;
    });

    > a {
        border: 1px solid @color-primary;
        border-radius: 50%;
        background-color: white;
        font-size: 1em;
        padding: 0;
        width: 2.5em;
        height: 2.5em;
        line-height: 2.5em;
        text-transform: uppercase;
        margin-bottom: 1em;
        margin-top: 1em;

        &:hover {
            background-color: @color-primary;
            color: white;
            border-color: @color-secondary;
        }

        &.active{
            background-color: @color-primary;
            color: white;
            border-color: white;
            width: 3em;
            height: 3em;
            line-height: 3em;
        }
    }
}

// in body buttons
//
nav.buttons{
    display: block;
    text-align: center;
    .on(@mobile, {
        > .button {
            margin-top: 2em;
        }
    })
}
//endregion

//region - nag bars
.nag {
    display: none;
    flex-direction: row;
    align-content: center;
    left: 0;
    right: 0;
    margin: 0;
    text-align: center;
    padding: 0.5em;
    z-index: 10;

    .on(@mobile,{
        padding: 1em;
        p {
            text-align: left;
        }
    });

    &.active {
        display: flex;
    }

    > * {
        margin-top: auto;
        margin-bottom: auto;
    }

    > :first-child {
        margin-left: auto;
    }


    &.report {
        position: absolute;
        top: 100%;
        background-color: white;
        color: @color-primary;

        a.button {
            padding: 0.3em;
            margin-right: auto;
        }
    }

    &.cookies {
        position: fixed;
        background-color: @color-primary;
        color: white;
        bottom: 0;

        a {

        }

        .close {
            color: white;
            padding: 0.4em;
            pointer-events: auto;
            cursor: pointer;
            align-self: center;
            margin: auto 1em auto auto;
            line-height: 1;
            .on(@mobile, {
                margin-right: 0;
            })
        }

        .on(@mobile, {
            flex-wrap: wrap;
            p{
                flex-grow: 2;
                order: 1;
                flex-shrink: 3;
                width: 50%;
            }
            .close {
                order: 2;
                margin: 0;
                flex-shrink: 2;
                margin-left: auto;
            }
            a {
                order: 3;
                margin-right: auto;
                margin-top: 1em;
                margin-left:0 ;
            }
        })
    }
}
//endregion

//region - global layout, sizing and vertical spacing
body > main {
    text-align: center;
    margin-bottom: 5em;

    //
    // http://alistapart.com/article/axiomatic-css-and-lobotomized-owls
    //
    * + * {
        margin-top: 3em;
        .on(@tablet,{
            margin-top: 2em;
        })
    }

    br,hr {
       margin-top: 0;
    }

    //This should be the one and only where width is restricted ....
    > section, > form > section, > nav, > form > nav {
        margin-left: auto;
        margin-right: auto;

        width: 60vw;

        .on(@4k, {  width: 80vw;  });
        .on(@tablet, { width: 80% });
        .on(@mobile, { width: 83% });
    }

    //hero style separator
    > header, > form > header {
        position: relative;
        height: 48vh;
        background-size: cover;
        background-position: center center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 0;

        > h1, > h2 {
            color: @color-white;
        }
    }
}
//endregion

//region - sproutforms
form.sprout{
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    text-align: left;

    input[type="text"], input[type="email"], input[type="tel"] {
        display: inline-block;
        width: 100%;
        outline: none;
        border: 0;
        border-bottom: 1px solid @color-black;
        padding: 0.5em 0;
    }

    > label {
        box-sizing: border-box;
        flex: 1 auto;
        display: flex;
        min-width: 40%;
        margin-right: 0.5em;
        margin-left: 0.5em;
        padding-bottom: 0.5em;
        white-space: nowrap;
        position: relative;

    }

    label[data-required]:after {
        content: '*';
        color: @color-primary;
        display: flex;
        flex-direction: column;
        margin-left: -0.5em;
        margin-top: 0.5em;
        margin-bottom: auto;
    }

    h3 {
        text-align: left;
        flex: 1 100%;
    }

    > div, > label > div {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        text-align: left;
        margin-top: 0;
        flex: 1 100%;

        > label {
            flex: 1 0;
            min-width: 30%;

            &:last-child:nth-child(even){
                flex: 2 2;
            }

            input {
                margin: 0;
                padding: 0;
                vertical-align: text-bottom;
            }
        }
    }

    label[for="fields-messageField"] {
        flex: 1 100%;
        border:0;
        padding: 0;
        border-bottom: 1px solid @color-black;
        textarea {
            border:0;
            outline: none;
            display: inline-block;
            box-sizing: border-box;
            padding: 0.5em 0;
            width: 100%;
            resize: none;
            & + .counter {
                position: absolute;
                right: 0;
                bottom: -1.5em;
                color: @color-primary;
            }
        }


    }

    input[type="submit"]{
        margin-right: auto;
        margin-left: auto;
    }

    .instructions {
        padding-top: 0.5em;
        margin-left: 0.5em;
        margin-right: 0.5em;
        color: @color-primary;
    }
}
//endregion

//region - tooltips, popups, dialogs and overlays

#tooltip {
    position: absolute;
    color: white;
    background-color: @color-black;
    width: auto;
    border-radius: 0.5em;
    padding: 1em;
    z-index: 500;
    padding-bottom: 3.5em;
    pointer-events: none;
    transition: opacity @transition-slow ease;
    will-change: opacity;
    opacity: 0;
    display: none;


    &.active {
        display: block;
        opacity: 1;
        pointer-events: auto;
        box-shadow: 0 3px 15px rgba(0,0,0,0.5);
    }

    button {
        position: absolute;
        left: 0;
        background-color: @color-success;
        padding: 1em;
        bottom: 0;
        z-index: 1;
        margin: 0;
        border: 0;
        right: 0;
        color: white;
        width: 100%;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        padding-top: 0.5em;
        white-space: nowrap;
    }

    &:after{
        content: '';
        position: absolute;
        bottom: -0.5em;
        left: ~"calc(50% - 0.5em)";

        background-color: @color-success;
        height: 1em;
        width: 1em;
        transform: rotate(45deg);
    }
}
#modal,.overlay {
    position: fixed;
    top:0 ;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.8);
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: -1;
    opacity: 0;
    display: none;
    margin-top: 0;
    transition: opacity 0.2s linear;
    pointer-events: none;
    will-change: opacity;

    &.active{
        opacity: 1;
        z-index: 9999;
        pointer-events: auto;
        display: flex;
    }

    > section {
        min-width: 50%;
        max-width: 80%;
        background-color: white;
        position: relative;
        margin: 0;

        .on(@tablet, {
            max-width: 100vw;
            width: 80%;
        });

        .on(@mobile, {
            width: 90%;
        });

        > article {
            text-align: center;
            display: flex;
            min-height: 50%;
            padding: 3em 2em;
            flex-direction: column;
            justify-content: center;
        }

        form{
            label{
                padding: 0.5em;
            }
            > div, > h3 {
                margin-right: 1em;
                margin-left: 1em;
            }
            > div {
                margin-top: 1em;
            }

            input[type=text], input[type=email]{
                appearance: none;
                border: 0;
                margin-left: auto;
                margin-right: auto;
                border-bottom: 1px solid @color-primary;

                color: @color-primary;
                outline: none;
                text-align: left;
            }

            input[type=submit]{
                margin-top: 1em;
                display: block;
                margin-left: auto;
                margin-right: auto;
                background-color: @color-primary;
                color: white;
            }
        }
    }
    body.resources & {
        > section {background: transparent;}
    }
    button.close {
        padding: 0.5em 1em;
        line-height: 1;
        background-color: @color-primary;
        color:white;
        border: none;
        margin-top: auto;
        margin-left: auto;
        margin-right: 0;
        border-radius: 0;
        position: absolute;
        right: 0;
        top: -2em;
    }

    .on(@mobile, {
        padding-top: 3em;
        > section {
            // height: 100%;
            margin-bottom: 0;
            margin-top: 0;

            > article {
                max-width: 100vw;

                > form{
                    > div {
                        overflow-y: auto;
                        overflow-scrolling: touch;
                        max-height: 30vh;
                    }

                    input[type=submit] {
                        margin-top: 1em ;
                    }
                }
            }
        }

        .field-countries{
            max-height: 34vh;
            overflow-scrolling: touch;
            overflow-y: auto;

        }
    })
}
//endregion

//region - list of questions and list of countries
section.countries {

    > header {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2em 0;

        > h2 {
            margin-top: 0;
            margin-right: 20px;
        }

        > button:not(.add) {
            margin: 0;
        }

        @media (max-width: 1023px) {
            flex-direction: column;

            > h2 {
                margin-right: 0;
                margin-bottom: 1.5rem;
            }
        }
    }

    > label,
    div > label {
        display: inline-block;
        text-align: center;
        color: @color-black;

        vertical-align: top;

        position: relative;
        box-sizing: content-box;

        margin: 1em 0;
        //margin-right: 2em;
        //margin-left: 2em;
        //margin-bottom: 1em;
        //margin-top: 1em;

        overflow: visible;

        padding-top: 6em;

        width: 8em;
        //min-width: 8em;
        //max-width: 8em;

        &.disabled {
            opacity: 0.2;
        }

        & > .language {
            position: absolute;
        }
    }

    button.add{
        margin-top: 0;
        border-radius: 50%;
        border: 1px dashed @color-primary;
        position: relative;
        width: 5em;
        height: 5em;
        margin-left: 2em;
        margin-right: 2em;
        transition: box-shadow 0.2s ease;
        display: inline-block;

        &:hover{
            background-color: inherit;
            color: inherit;
        }

        &:after, &:before {
            content:'';
            background-color: @color-primary;
            position: absolute;
            top: 1.4em;
            left: 1.4em;
            width: 2em;
            height: 2em;
            overflow: hidden;
        }

        &:after {
            height: 2px;
            margin: 1em 1px;
        }
        &:before {
            width: 2px;
            margin: 1px 1em;
        }
    }

    button:not(.add) {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    input[type=checkbox] {
        display: none;
    }

    button.add {
        position: absolute;
        margin-left: auto;
        margin-right: auto;
    }

    //shared layer styles
    label > span, label > button {
        position: absolute;

        height: 5em;
        width: 5em;

        left:0;
        top:0;
        bottom: 0;
        right: 0;
        display: block;

        margin-top: 0;
        margin-left: auto;
        margin-right: auto;

        outline: none;

        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100%;
        border-radius: 50%;

    }

    //region -- localized
    @d: 70px;

    .l-locales {
        transition: height 200ms, width 200ms;
        height: @d;
        width: @d;
        z-index: 5;
        position: absolute;

        display: flex;

        > button{
            position: relative;
            z-index: 2;
            opacity: 0;
            font-family: icomoon, sans-serif;
            color: white;
            background-color: @color-black;
            width: @d;
            height: @d;
            max-width: @d;
            max-height: @d;
            padding: 0;
            margin: 0;
            flex-grow: 1;
            flex-basis: @d;
            border: 0;
            border-radius: 0;
            &:first-child{
                border-bottom-left-radius: @d;
                border-top-left-radius: @d;
            }
            &:last-child{
                border-bottom-right-radius: @d;
                border-top-right-radius: @d;
            }
        }

        > .selection {
            transition: height 500ms, width 500ms, background-color 200ms;
            position: absolute;
            z-index: 1;

            height: auto;
            width: auto;
            min-width: 42px;

            display: flex;
            flex-direction: row;
            align-items: flex-end;
            //border-radius: 5px;
            flex-basis: 50%;

            bottom: 0.5em;
            left: 50%;
            transform: translateX(-50%);

            //border: 1px solid rgba(0,0,0,0.5);

            > button {
                transition: height 500ms, width 500ms, background-color 200ms;

                color: white;
                padding: 3px 4px;
                margin: 0;
                background-color: @color-black;
                width: 50%;
                font-size: 90%;
                border: 0;
                border-radius: 0;
                line-height: 1;
                outline: none;

                &:first-child {
                    border-radius: 3px 0 0 3px;
                }

                &:last-child {
                    border-radius: 0 3px 3px 0;
                }

                &.checked {
                    background-color: @color-success;
                    color: white;
                }
            }
        }

    }
    //endregion

    //region -- unchecked state

    //Image layer
    .l-icon {

        box-sizing: border-box;

        transition:
            opacity @transition-fast ease-in-out,
            transform @transition-fast ease-in-out;
        //will-change: opacity, transform, border-width;
        z-index: 1;

    }
    .l-check {
        //noinspection CssNoGenericFontName
        font-family: icomoon;
        font-size: 20px;
        line-height: 1.5em;

        border-color: @color-black;
        background-color: @color-black;

        top: -5px;
        right: -60px;
        height: 1.5em;
        width: 1.5em;
        max-width: none;
        min-width: initial;

        opacity: 0;
        transform: scale(0.1);

        transition:
            opacity @transition-fast ease-in-out,
            transform @transition-fast ease-in-out;
        //will-change: opacity, transform;

        //Blur layer
        &:after {
            content: '\e90b';
            color: white
        }
        z-index: 4;
    }
    .l-border {
        //will-change: border-color, border-width;
        background-color: @color-black;

        transition:
            opacity @transition-fast ease-in-out,
            transform @transition-fast ease-in-out;

        z-index: 0;
        @media (max-width: 1024px) {
           
        }
    }
    .l-shadow {
        box-sizing: border-box;

        filter: blur(5px);

        transition:
            box-shadow @transition-fast ease-in-out,
            transform @transition-fast ease-in-out,
            opacity @transition-fast ease-in-out;
        //will-change: opacity;
        opacity: 0;
        top: 8px;
        z-index: -1;
    }
    //endregion

    //region -- checked state
    :checked {
        ~ .l-icon {
            transform:  none;
        }
        ~ .l-border{
            transform:  scale(1.2);
            opacity: 1;
        }
        ~ .l-check{
            opacity: 1;
            transform:  scale(1);
        }
        ~ .l-shadow {
            opacity: 0;
        }
    }
    //endregion

    //region -- hover state
    label:hover:not(.split) {
        //Scale image
        :not(:checked) ~.l-icon {
            transform:  scale(1.05);
        }
        @media (min-width: 1024px) {
            .l-border{
                transform:  scale(1.2);
                opacity: 1;
            }
        }          
        .l-shadow{
            transform:  scale(1.05);
            opacity: 0.9;

        }
    }
    // for ie, edge
    label.split:hover {
        .l-border,
        .l-shadow {
            opacity: 0;
        }
    }
    //endregion

    .split {
        > span.l-check, > span.l-border, > span.l-icon {
            opacity: 0;
            z-index: -1;
        }

        > .l-locales {
            opacity: 1;
            pointer-events: auto;
            width: @d * 2;
            transform: translateX(-@d/2);

            > button{
                opacity: 1;

                &.disabled {
                    opacity: .25;
                    cursor: default;
                    pointer-events: none;
                }
            }

            > .selection {
                position: static;
                display: flex;
                flex-direction: row;
                margin: auto;
                transform: none;
                border-radius: 0;
                flex-basis: 100%;
                height: 70px;
                padding: 0;
                border: 0;
                text-align: center;
                border-left: 1px solid transparent;
                border-right: 1px solid transparent;

                > button {
                    height: 70px;
                    border-radius: 0;

                    &:first-child{
                        margin-right: 1px;
                    }
                }
            }
        }

    }
}

.disclaimer {
    font-size: 90%
}

@supports (-ms-ime-align:auto) {
    section.countries{
        .l-shadow {
            filter: none;
            top: 0;
            opacity: 0;
            transform:  none;
            box-shadow: none;
        }
        label:hover {
            .l-shadow {
                opacity: 1;
                transform:  scale(1.05);
                box-shadow: 0 3px 20px rgba(0, 0, 0, 0.9);
            }
        }
    }
}

@media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    section.countries{
        .l-shadow {
            filter: none;
            top: 0;
            opacity: 0;
            transform:  none;
            box-shadow: none;
        }
        label:hover {
            .l-shadow {
                opacity: 1;
                transform:  scale(1.05);
                box-shadow: 0 3px 20px rgba(0, 0, 0, 0.9);
            }
        }
    }
}

section.category {
    //compact version for overview
    &.compact{


        article {
            border: 2px solid @color-primary;
            background-color: white;
            transition: height @transition-slow ease-in-out;
            &:empty {
                display: none;
            }

            div {
                margin-top: 0;
                border: 0;
                & + div {
                    border-top: 1px solid @color-primary-lighter;
                }
            }
            &:not(:empty) + article {
                position: relative;
                &:before {
                    content: '';
                    position: absolute;
                    height: 5em;
                    top: -5em;
                    left: 50%;
                    display: block;
                    width: 5px;
                    margin-left: -3px;
                    z-index: -1;
                    background-color: @color-primary;
                }
            }
        }
        .question{
            transition: opacity @transition-slow ease,filter @transition-slow ease;
        }
        .question:not(.selected){
            pointer-events: none;
            opacity: 0.8;
            filter: grayscale(100%) blur(2px);
        }
        .question.selected{
            background-color: white;
            color: @color-black;
            border-color: @color-primary;
            pointer-events: auto;
            i {
                color: @color-primary;
            }
            label{
                color: @color-primary;
                border-color: @color-primary;
            }
        }

        .question:hover {
            background-color: white;
            i {
                background-color: white;
                color: @color-primary;
                border-color: @color-primary;
            }
            label {
                color: @color-primary;
                border-color: @color-primary;
            };
        }

        .add {
            min-height: 5em;
            border: 1px dashed @color-primary;
            line-height: 5em;
            vertical-align: middle;
            text-align: center;
            display: block;
            font-size: @font-size-large;
            margin-top: 3em;
            border-radius: 5px;
            &:hover{
                color: @color-primary;
                background-color: inherit;
            }
        }
    }

    h2 {
        font-weight: normal;
        color: @color-black;
        text-align: left;
        text-transform: none;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        button{
            white-space: nowrap;
        }
    }

    .question {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-content: stretch;
        padding: 2em 1em;
        border: 1px solid @color-primary;

        transition-property: color, background-color, border-color;
        transition-duration: @transition-fast,@transition-fast,@transition-fast;
        transition-timing-function: ease,ease,ease;

        mark.hit{
            background-color: yellow;
        }

        > * {
            margin: 0;
        }

        i {
            margin-right: 1em;
            margin-top: auto;
            margin-bottom: auto;
            text-align: center;
            line-height: 3em;
            display: block;
            min-width: 3em;
            min-height:3em;
            padding:0;
            box-sizing: border-box;
            color: @color-primary;

            .on(@mobile, {
                font-size: 14px;
            })
        }

        p {
            text-align: left;
            border-left: 1px solid @color-primary;
            margin-right: auto;

            padding-left: 1em;
            padding-top: 0.5em;
            padding-bottom: 0.5em;
        }

        input[type="checkbox"]{
            display: none;
        }
        label{
            margin-top: auto;
            margin-bottom: auto;
            margin-left: 2em;
            margin-right: 2em;

            border: none;
            border-radius: 0;
            position: relative;
            min-width: 2em;
            height: 2em;

            outline: none;

            transform-origin: center center;
            transition: transform 0.5s linear;
            will-change: transform;
            box-sizing: content-box;

            &:after, &:before {
                content:'';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                height: 0;
                width: 0;
                margin: 0;
                padding: 0;
                border: 1px solid @color-primary;
                overflow: hidden;
            }
            &:after {
                height: 100%;
                margin-left: 1em;
                margin-right: 1em;
            }
            &:before {
                width: 100%;
                margin-top: 1em;
                margin-bottom: 1em;
            }
        }

        .on(@mobile,{
            padding: 1em;
            label{
                display: none;
            }
        });

        &:hover {
            border-color: @color-success;
            background-color: white;
            i{
                color: white;
                background-color: @color-success;
            }
            i, p {
                border-color: @color-success;
            }
            label:after, label:before {
                border-color: @color-success;
            }
        }
        &.selected{
            background-color: @color-success;
            color: white;
            border-color: white;
            i {
                background-color: transparent;
                color: inherit;
                border-color: inherit;
            }
            p{
                border-color: inherit;
                color: inherit;
            }
            label{
                transform: rotate(45deg);
            }
            label:after, label:before {
                border-color: inherit;
            }
            &:hover {

                label:after, label:before {
                    border-color: @color-highlight;
                }
            }
        }
    }
}
//endregion

//region - report and and their shared styles
body.report, body.hub {
    header {
        .indicator {
            margin-left:auto;
            margin-right:auto;
            color: white;
            border-color: white;
        }
        .scrollmagic-pin-spacer{
            width: 100%;
            max-width: 100%;
        }
        box-sizing: border-box;
    }

    section{
        h2 {
            font-size: 150%;
            font-weight: 500;
            color: @color-black;
            overflow: hidden;
            vertical-align: middle;
            text-transform: uppercase;

            > button{
                font-size: 70%;
                float: right;
                margin-right: 0;
                margin-left: 1em;
                padding: 0.5em 1em;
                line-height: 1;
            }
        }

        &.compact {
            h2{
                text-align: center;
                display: block;
            }
            .on(@mobile, {
                .add {
                    margin-top: 2em;
                }
            })
        }

        &.countries{
            text-align: center;
        }
    }

    main > footer, main > form > footer {
        padding-top: 3em;
        padding-bottom: 5em;

        h2 {
            color: white;
        }

        > section {
            max-width: 50%;
            margin: 0 auto;
            text-align: center;
            .on(@mobile, {
                max-width: none;
            })
        }

        background-color: @color-primary-lighter;
        color: @color-white;
        display: flex;
        flex-direction: row;

        img {
            max-width: 100%;
        }

        .group-by label {
            cursor: pointer;
            display: inline-block;
            padding: 0.5em 2em;
            border: 1px solid white;
            color: white;
            border-radius: 5px;
            outline: none;
            margin-left: 1em;
            margin-right: 1em;
            box-shadow: 0 3px 15px rgba(0,0,0,0.2);

            input[type=radio] {
                display: none;
            }

            &.checked{
                background-color: white;
                color: @color-primary;
            }
        }
    } 
}
body.report{
    main{
        margin-bottom: 0;
    }
    nav.secondary {
        height: 3.5em;
        justify-content: space-between;

        &.report {
            &.empty {display: none;}
        }

        &:empty{
            display: none;
        }

        & > * {
            display: flex;
            align-items: center;
        }

        .selected {
            max-width: 50%;
            overflow: hidden;
            margin-right: auto;

            span {
                margin-left: 1em;
            }

            button {
                .indicator;
                font-size: @font-size-small;
                color: @color-white;
                line-height: 3em;
                height: 3em;
                width: 3em;
                border-color: white;
                margin-right: 0.5em;
                pointer-events: none;
            }

            .on(@mobile,{
                display: none;
            });

            &:first-child {
                background: transparent;
            }
        }
        > :last-child {
            //margin-right: -1em;
            //padding-right: 1em;
            margin: 0 -1em;
            padding: 0 1em;

            height: 100%;
            box-sizing: border-box;
        }

        > div {
            align-self: center;
            color: white;
            font-size: @font-size-above;
            .on(@mobile,{
                display: none;
            })
        }

        [data-rel="reset"], [data-rel="save"] {
            margin-left: auto;
        }
    }
}
body.report.category.all {
    form header h1 {
        font-size: 3em;
    }
}
body.questions{
    nav.bottom{
        transition: transform 200ms ease;

        display: block;
        position: fixed;
        margin: 0;
        padding: 0;
        bottom: 0;
        background-color: @color-primary;
        color: white;
        width: 100%;
        z-index: 10;


        > a{
            display: block;
            padding: 1em;
            color: @color-white
        }

        &.hide{
            transform: translateY(100%);
        }
    }
    header {
        > p {
            color: white;
        }
    }

    header:not(:first-child){
        margin-top: 2em;
    }
    input#questions-search {
        display: block;
        border: 1px solid @color-gray-light;
        border-radius: 5px;
        width: 100%;
        max-width: ~"calc(710px - 8em - 8px)";
        margin-left: auto;
        margin-right: auto;
        padding: 1em 1.5em;
        outline: none;
        text-align: left;
        position: relative;
        box-sizing: border-box;

        & + label {
            margin-top: 1em;
            display: block;
            min-height: 1em;
        }
    }
}
body.hub{
    &.questions.preview, &.questions.all {
        .nag.report{
            display: flex;
        }
    }

    &.data-analysis, &.indicators{
        section.indicators > article {
            border: 1px solid @color-primary-lighter;
            padding: 1.5em;

            header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                border-bottom: 0 solid @color-primary;
                padding-bottom: 0;

                > * {
                    margin-top: auto;
                    margin-bottom: auto;
                }

                h2 {
                    flex-shrink: 0;
                    display: inline-block;

                    color: @color-primary;
                    border-right: 1px solid @color-primary;
                    padding: 1em;
                    margin-right: 1em;
                    padding-left: 0;
                    .on(@mobile, {
                        font-size: 14px;
                    });
                }

                h1 {
                    position: relative;
                    text-align: left;
                    flex-grow: 1;
                    color: @color-black;
                    font-size: @font-size-large;
                    font-weight: normal;
                    line-height: 1.1;
                    .on(@tablet, {
                        margin-right: 30px;
                    });                    
                    .on(@mobile, {
                        font-size: 14px;
                    });
                    @media (max-width: 468px) {
                        margin-right: 0;
                    }
                }

                button{
                    padding: 13px;
                    margin-left: 0;
                    margin-right: 0;
                    font-size: 150%;
                    border: 0;
                    transition: transform 0.5s ease;
                    cursor: pointer;
                    background: url(/assets/misc/chevron.png) no-repeat 0 50%;
                    background-size: contain;
                    &:hover{
                        background-color: inherit;
                        color:inherit;
                    }

                    .on(@mobile, {
                        padding: 0.5em;
                    });
                }
            }

            p{
                display: none;
                text-align: left;
            }

            &.open{
                padding-bottom: 2em;
                header{
                    border-bottom-width: 2px;
                    padding-bottom: 1.5em;
                }
                button{
                    transform: rotate(180deg);
                }
            }
        }
    }

    nav.secondary {
        :last-child {
            margin-right: auto;
        }
        :first-child {
            margin-left: auto;
        }

        .on(@mobile,{
            max-height: 3.5em;
            display: block;
            will-change: max-height;

            :last-child {
                margin-right: 3em;
            }

            &:after {
                content: '⌵';
                color: white;
                font-weight:bold;
                position: absolute;
                right: 2em;
                top: 1.2em;
                transition: transform 0.2s ease-in;
                will-change: transform;
                transform: rotate(180deg);
            }

            > a {
                padding: 1em;
                padding-right: 0;
                padding-left: 3em;
                margin-right: 3em;

                display: block;
                pointer-events: none;

                &:not(.active){
                    display: none;
                }
            }

            &.open {
                max-height: none;

                &:after {
                    transform: rotate(0deg);
                }
                > a {
                    position: relative;
                    display: block;
                    pointer-events: auto;
                    margin-right: 3em;
                }
            }

        })
    }

    header > nav{
        :first-child {
            margin-left: auto;
        }
        :last-child {
            margin-right: auto;
        }
    }
    .question{
        h2{
            text-align: left;
        }
        pointer-events: none;
    }
}
//endregion

//region - homepage full screen header crap
body.homepage {
    transition: background-color @transition-fast ease;
    will-change: background-color;

    > nav.main{

        transition: background-color @transition-fast ease, padding-top @transition-slow ease;
        will-change: padding-top, background-color;

        a {
            color: @color-black;
            will-change: color;
            transition: color 0.5s ease;
            > img.alt {
                display: none;
            }
        }

        .privacy{
            display: none;
        }
    }
    > nav.main.alt {
        background-color: transparent;
        padding-top: 2.5em;


        .on(@mobile, {
            padding-top: 0;
        });

        .privacy {
            position: absolute;
            display: flex;
            top: 1em;
            right: 2.5em;
            > a {
                padding-left: 0.5em;
                padding-right: 0.5em;
            }
        }

        a {
            color: white;
            > img {
                display: none;
            }
            > img.alt {
                display: block;
            }
        }

        .hamburger:not(.is-active) {
            > span > span {
                background-color: @color-white;
                &:before, &:after {
                    background-color: @color-white;
                }
            }
        }
    }

    .on(@tablet, {
        > nav.main.open {
            background-color: white;

            a {
                color: @color-black;
                > img {
                    filter: none;
                }
            }
        }
    });

    &.dark{
        h1, h2, p{
            color: rgba(255, 255, 255, 0.8);
        }
    }

    svg {
        position: absolute;
        left: 0;
        top: 0;
        margin: 0;
        padding: 0;

        > * {
            margin-top: 0;
        }

        path {
            stroke: rgba(255,255,255, 0.5);
            stroke-width: 10;
            fill: none;
            stroke-linejoin: miter;
            stroke-linecap: square;
            vector-effect: non-scaling-stroke;
        }
    }

    main {
        padding-bottom: 0;
        margin-bottom: 0;

        h1 {
            text-transform: uppercase;
        }

        > .scrollmagic-pin-spacer {
            overflow: hidden;
            max-width: 100vw;
            max-height: 100vh;
        }

        > header {
            height: 100vh;
            box-sizing: border-box;

            .on(@mobile, {
                height: auto;
                min-height: 100vh;
                padding-top: 10vh;
                padding-bottom: 15vh;
            });

            h1, h2, p {
                color: @color-white;
                width: 40%;
                margin-left: auto;
                margin-right: auto;

                @media (max-width: 1024px) {
                    width: auto;
                    padding-right: 70px;
                    padding-left: 70px;
                }

                @media (max-width: 767px) {
                    width: ~"calc(100vw - 2em)";
                    padding: 0;
                }
            }

            section {
                height: auto;

                .button:not(.scroll-down) {
                    width: 214px;
                    margin-top: 0;
                    padding: .50em 0;
                    font-size: 18px;
                    line-height: 1.15;
                    font-weight: 300;

                    @media (max-width: 786px) {
                        display: block;
                        margin-right: auto;
                        margin-left: auto;

                        & + .button {
                            margin-top: 1em;
                        }
                    }
                }
            }

            .icon.scroll-down {
                padding: 0;
                color: white;
                border-color: white;
                border: none;
                margin: 0 auto;
                font-size: 3em;
                position: absolute;
                bottom: 2.5vh;
                left: ~"calc(50vw - 22px)";
                cursor: pointer;
                &:hover {
                    color: white;
                    background-color: transparent;
                }

                @media (max-width: 1024px) {
                    bottom: 6vh;
                }
                @media (max-width: 374px) {
                    display: none;
                }                
          
            }
        }

        > section {
            box-sizing: border-box;
            margin-top: 0;
            margin-bottom: 0;
            height: 50vh;
            position: relative;
            width: 100%;

            .on(@mobile, {
                height: 90vh;
            });

            &.about-section-11 {
                form {
                     .on(@mobile, {
                        padding-bottom: 60px;
                    });                   
                }
            }

            h1 {
                margin-top: 0;
                margin-left: auto;
                margin-right: auto;
                display: block;
                background-color: inherit;
                position: relative;
                will-change: background-color;
                transition: background-color @transition-fast ease;
                z-index:1;
            }

            > article, > form {
                top: 47.5%;
                position: relative;
                margin-top: 0;
                width: 60%;
                margin-left: auto;
                margin-right: auto;
                background-color: inherit;

                h1 {
                    display: inline-block;
                    text-align: left;
                    padding-right: 1em;
                    padding-left: 1em;
                    .on(@mobile, {
                        padding:1em;
                    })
                }

                .on(@mobile, {
                    max-width: 100%;
                    width: auto;
                    margin-left: 1em;
                    margin-right: 1em;
                });
            }

            > article + form {
                padding-top: 0;
            }

            > p {
                max-width: 60%;
                margin-left: auto;
                margin-right: auto;
                background-color: inherit;
            }

            .on(@mobile, {
                h1, h2, p {
                    margin-left: auto;
                    margin-right: auto;
                    padding-top: 0;
                    margin-top: 0;
                    padding-left: 1em;
                    padding-right: 1em;
                    text-align: center;
                }
            });
            &.white.top.pull{
                background-color: #ffffff;
                h1 {
                    display:inline-block;
                }
            }
            &.white:not(.top):not(.pull) {
                background-color: white;
                h1, h2 {
                    background-color: white;
                }
                .on(@mobile,{
                   &.right {
                        p {
                            margin-top: 0;
                            margin-right: 2em;
                        }
                    }
                });
            }

            &.embed {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-content: center;

                .play {
                    margin-top: 0;
                    margin-bottom: 0;
                    width: 15vw;
                    margin-left: auto;
                    margin-right: auto;
                    display: inline-block;
                    padding: 0;
                    border: 0;
                    max-width: 8%;

                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);

                    position: absolute;
                    z-index: 1;
                    transition: transform @transition-fast ease;
                    will-change: transform;

                    .on(@tablet,{
                        max-width: 10%;
                    });

                    .on(@mobile,{
                        max-width: 15%;
                    });

                    img{
                        width: 100%;
                    }
                    &:hover{
                        background-color: transparent;
                        color: white;
                        transform: translate(-50%, -50%) scale(1.1);
                    }
                    .on(@mobile, {
                        width: 40px;
                    });                      
                }

                > .player {
                    flex-direction: column;
                    max-width: 100vw;
                    max-height: 100vw;
                    position: absolute;
                    top: 0;
                    left: 0;
                    .close {
                        margin-top: auto;
                        margin-left: auto;
                        display: none;
                    }
                    iframe {
                        width: 100%;
                        height: 100%;
                        max-height: 100%;
                        margin-top: 0;
                    }
                }
            }
            &.embed, &.image{
                width: auto;
                padding-top: 0;
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
            }
            &.embed, &.image, &.loop{
                height: 100vh;
            }

            &.pull.right{
                height: 50vh;

                > article {
                    position: relative;
                    margin-right: 0;
                    margin-left: 0;
                    left: 35%;
                    top: 14%;
                    max-width: 52%;
                    margin-top: 0;
                    text-align: left;
                    padding-left: 14px;
                    padding-right: 14px;
                    box-sizing: border-box;
                }
                .on(@mobile, {
                    > article {
                        max-width: none;
                        display: block;
                        margin-left: auto;
                        padding-left: 0;
                        left: 0;
                        padding-right: 0;
                        text-align: center;
                        width: auto;

                        h1{
                            padding-top: 1em;
                        }

                        p{
                            padding-right: 5em;
                        }
                    }
                })
            }

            &.pull.bottom{
                height: 25vh;
                padding-top: 15vh;
                padding-bottom: 5vh;
            }

            &:last-child{
                > article, > form {
                    top: 22.5%
                }
                height: 75vh;
            }

            .on(@laptop, {
                > article, > form {
                    top: 45.5%;
                }
                &:last-child {
                    top: 21.5%;
                }
                &.pull.right{
                    > article {
                        top: 10.5%
                    }
                }
                &.loop {
                    > article{
                        h1 {
                            top: 3%;
                        }
                    }
                }
            });
            .on(@tablet, {
                > article, > form {
                    top: 45%;
                    width: 70%;
                }
                &:last-child {
                    top: 21%;
                }
                &.pull.right{
                    > article {
                        top: 10%;
                    }
                }
            });
            .on(@mobile, {
                > article, > form {
                    top: 44%;
                    width: 90%;
                }
                &.pull.top{
                    article{
                        padding-top: 17vh;
                        z-index: 1;
                    }
                    h1{
                        font-size: @font-size-huge;
                        padding: 0;
                        width: auto;
                        display: block;
                    }
                }
                &.pull.right{
                    height: 60vh;
                    > article {
                        top:6%
                    }
                }
                &.pull.bottom{

                }

                &.left {
                    h1{
                        margin-right: 2em;
                    }
                    p{
                        padding-left: 2em;
                    }
                }
            });
        }

        section.loop {
            z-index: 0;
            height: 100vh;

            .on(@mobile, {
                min-height: inherit;
                margin-top: 10vh;
            });

            h1{
                position: absolute;
                top: 3.5%;
                left: 5%;
                margin: 0;


                .on(@mobile, {
                    position: static;
                    padding-bottom: 0.5em;
                    margin-right: 2em;
                    width: auto;
                    padding-top: 0.3em;
                })
            }

            svg {
                z-index:-1;
            }

            > article, > div > article{
                margin-left: auto;
                margin-right: auto;

                > article{
                    padding-top: 0;
                    display: none;
                    &.active, &.start, &.end {
                        display: flex;
                        position: absolute;
                        top: 0;
                        margin: 0 auto;
                        width: 35%;
                        left: -5%;
                        right: -5%;
                        padding: 15%;
                        height: 70vh;
                        flex-direction: column;
                        justify-content: center;
                        > h2 {
                            font-size: @font-size-huge;
                            flex-grow: 1;
                            margin-top: 1em;
                        }
                        > p {
                            font-size: @font-size-large + 10px;
                            margin-top: 0;
                            flex-grow: 1.5;

                            margin-left: auto;
                            margin-right: auto;
                            max-width: 35vw;
                            .on(@tablet, {
                                font-size: @font-size-large + 5px;
                            });
                            .on(@mobile, {
                                flex-grow: 0;
                                max-width: none;
                            })
                        }

                        .on(@4k, {
                            width: 30%;
                        });
                        .on(@mobile, {
                            width: auto;
                        })
                    }
                }

                > section {
                    position: absolute;
                }

            }

            svg {
                margin-left: auto;
                margin-right: auto;
                overflow: visible;
                right: 0;

                path {
                    z-index: 1;
                }
            }
        }

        section.white {
            svg path {
                stroke: rgba(red(@color-primary), green(@color-primary), blue(@color-primary), 0.5);
            }
        }

        form{
            z-index: 1;
            max-width: 60vw;

            label {
                flex: 1 0 40%;
            }
        }
    }


}
//endregion

//region - fixed screen layout for forms/messages
body.contact, body.thank-you{
    main {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 0;
    }
    .on(@tablet, {
        .sprout{
            margin-left: 2em;
            margin-right: 2em;
        }
    })
}
//endregion

//region - actual report that will be rendered as pdf
body.report-template{
    font-family: Arial, sans-serif;
    letter-spacing: normal;
    line-height: 1.5;
    font-size: 16px;
    color: black;

    * + * {
        margin-top: 1em;
        width: 100%;
    }

    .primary{
        color: @color-primary;
    }

    > ul {
        display: block;
        page-break-after: avoid;
        padding: 0;
        li {
            margin-left: 1em;
            page-break-inside: avoid;
        }
        a {
            display: inline;
            margin: 0 0;
            padding: 0;
        }
    }

    main {
        box-sizing: border-box;
        min-height: 100vh;
        padding-right: 5em;
        overflow: visible;
    }

    main, nav, header, > ul {
        text-align: left;
    }

    main, main > section {
        > h1 {
            page-break-after: avoid;
            color: @color-primary;
            line-height: 2em;
            vertical-align: middle;
            display: flex;

            strong{
                color: @color-black;
            }
        }
    }

    main.cover {
        background-size: contain;
        background-position: top center;
        background-repeat: no-repeat;
        position: relative;

        > footer {
            position: absolute;
            bottom: 0;
            left: 1em;
            right: 1em;
            height: 15vh;

            ul {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                width: 100%;
                padding: 0;
                margin-top: 0;
                li{
                    display: inline-block;
                    flex-basis: 25%;
                    margin: 0;
                    flex-grow: 1;
                    flex-shrink: 0;
                }
            }
        }
    }

    main.intro {

    }

    h1 > i{
        font-style: normal;
        width: 2em;
        height: 2em;
        display: inline-block;
        border: 1px solid @color-black;
        background-position: center center;
        background-size: 100%;
        border-radius: 50%;
        margin-right: 0.5em;
    }

    strong {
        color: black;
        font-weight: bold;
    }

    hr {
        page-break-after: always;
        margin-bottom: 1em;
    }

    p{
        font-family: Arial, sans-serif;
        page-break-inside: avoid;
    }

    section{
        width: auto;
        box-sizing: border-box;

        > h2{
            color: @color-primary;
            font-size: 24px;
        }

        > h3 {
            color: @color-primary;
            font-weight: lighter;

        }

        > h4{
            color: @color-black;
            font-weight: bold;
            font-size: 16px;

            > span {
                color: @color-primary;
                margin-bottom: 1em;
            }
        }

        > h2, > h3, > h4{
            overflow: hidden;
            margin-bottom: 1em;
            page-break-after: avoid;
            page-break-inside: avoid;
        }
    }
    table{
        box-sizing: border-box;
        width: 100%;
        page-break-inside: avoid;
        border: 1px solid ;
        border-collapse: collapse;
        td, th {
            page-break-inside: avoid;
            padding: 0.5em;
            vertical-align: middle;
            border: 1px solid @color-gray-light;
            > * {
                margin: 0;
                padding: 0;
                display: inline;
            }
        }
    }
    img {
        box-sizing: border-box;
        max-width: 100%;
        display: block;
        page-break-inside: avoid;
    }
}
@media print {
  body.report-template {
    margin: 0 10mm;
    main {
        padding-right: 0;
        h1 {
            align-items: center;
            line-height: 1em !important;
            vertical-align: top;
        }
        h1.chapter {align-items: flex-start;}
    }
  }
}
//endregion

//region - event stream page
body.stream {
    background-color: @color-primary;
    iframe {
        margin-top: 0;
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    main {
        background-image: url(/assets/bg_stream_outlines.png);
        background-repeat: no-repeat;
        background-position: top center;
        background-size: 100%;

        section {
            color: white;
            padding-top: 10vh;
            > img {
                max-width: 100%;
            }
        }
        h1,h2,h3 {
            color: white;
            text-transform: uppercase;
        }
        .play {
            display: none;
        }
        .sketchfab-embed-wrapper{
            position: relative;
            padding-bottom: 66.5%;
            width: 100%;
        }
        .player {
            margin-top: 3em;
            position: relative;
            padding-bottom: 66.5%;
            opacity: 1;
            display: block;
            z-index: auto;

            .close {
                display: none;
            }
        }
    }
    nav.main {

        &:not(.open){
            background: @color-primary;
            position: fixed;


            a, .active, a:hover, a:visited {
                color: white;
            }
        }

        .hamburger:not(.is-active) {
            > span > span {
                background-color: @color-white;
                &:before, &:after {
                    background-color: @color-white;
                }
            }
        }

        .brand > img {
            display: none;
            &.alt {
                display: block;
            }
        }
    }
}
//endregion


//region - resource hub

body > main > header.page-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    padding-top: 12rem;
    padding-bottom: 3rem;

    @media (max-width: 767px) {
        padding-top: 8rem;
        padding-bottom: 2rem;
    }

    & > h1 {
        margin: 0;
        font-size: 3rem;
        letter-spacing: .06em;
        text-align: center;
        color: #FFF;

        @media (max-width: 767px) {
            font-size: 2.285rem;
        }
    }

    & > div {
        display: flex;
        align-items: center;

        & > .button {
            margin: 0 1rem;
            padding: .5em 1.5em;
            font-size: 18px;
            line-height: 1.15;
            font-weight: 300;
            color: #FFF;
            border-color: #FFF;

            @media (max-width: 767px) {
                margin: 0 .25rem;
                font-size: 16px;
                color: @color-primary;
                background: #FFF;
            }
        }
    }

    &:before {
        content: '';
        position: absolute;
        z-index: -100;
        top: 0;
        right: 0;
        left: 0;
        height: 620px;
        background-image: url('/assets/bg_stream_outlines_2.png'), linear-gradient(to right, #009cdb 0%, #4fbddb 100%);
        background-repeat: no-repeat;
        background-position: center top;
        background-size: cover;

        @media (max-width: 1023px) {
            background-position: center top 120px, center top;
            background-size: 120%;
        }

        @media (max-width: 767px) {
            background-position: left -100px top 80px, center top;
            background-size: 200%;
        }
    }

}

body > main > article.page-container {
    width: 860px;
    max-width: 100%;
    margin: 3rem auto 2rem;
    padding: 30px;
    background-color: #FFF;
    box-shadow: 0 0 1px 1px rgba(0,0,0,.15);
    box-sizing: border-box;

    @media (max-width: 1023px) {
        width: auto;
        margin-right: 2rem;
        margin-left: 2rem;
    }

    @media (max-width: 767px) {
        margin-right: 1rem;
        margin-left: 1rem;
    }
    @media (max-width: 500px) {
        margin-right: 0;
        margin-left: 0;
    }    
}

.hub.executive-summaries > main > article.page-container {
    padding-right: 20px;
    padding-left: 20px;
}

.resources main > article.page-container {
    z-index: 1;
    padding: 0 15px;
    background-color: transparent;
    box-shadow: none;

    // @media (max-width: 1023px) {
    //     margin-top: 15rem;
    // }

    // @media (max-width: 767px) {
    //     margin-top: 10rem;
    // }

    h2 {
        font-size: 42px;
        text-transform: uppercase;
        font-weight: 700;
        @media (max-width: 400px) {
            font-size: 32px;
        }        
    }

    & > .sorting {
        margin-top: 1rem;

        &,
        & > div {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
        }

        & > div {
            margin: 0;

            & > button,
            & > span {
                display: inline-block;
                margin: 0;
                padding: .4em .6em;

                font-family: @font-family-structure;
                font-size: 18px;
                font-weight: 300;
                line-height: 1.4;
                color: @color-gray-dark;
                text-align: left;
                border: 1px solid transparent;
                background: #FFF;
                border-radius: 3px;
                transition: all .25s ease-out;
                white-space: nowrap;
            }

            & > span {
                margin-right: 1rem;
                cursor: default;

                .close {
                    display: inline-block;
                    margin-left: 4px;
                    font-size: 18px;
                    line-height: 1.3em;
                    color: #9f9f9f;
                    cursor: pointer;
                    vertical-align: text-top;
                    transition: all .25s ease-out;
                }

                &.video {
                    color: #DC016B;
                }

                &.report {
                    color: #455560;
                }

                &.presentation {
                    color: #CBD302;
                }

                &.document {
                    color: #009CDB;
                }
                &.disabled {
                    background: transparent;
                    border-color: #fff;
                    color: #fff;
                    & > span {
                        color: #fff;
                        -ms-transform: rotate(45deg); /* IE 9 */
                        -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
                        transform: rotate(45deg);
                    }
                }
            }
        }

        & > .dropdown {
            position: relative;
            margin-top: 1rem;
            margin-right: 1rem;

            & > button {
                min-width: 160px;
                padding-right: 2em;
                background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAJCAMAAADaUYZlAAAAQlBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACO4fbyAAAAFnRSTlMAAgYICg8RJCwtMDdZh56go6Wpq6ytgoQrBwAAAEVJREFUeNpNyNEOgCAMQ9E6NxGnMlH+/1cNsYncl+YUE8YWnGmgF1hbf4YAVjO5XdJHeeSq36+Pk2wOR7o7mcTeDENykC937AG7Rn+jrgAAAABJRU5ErkJggg==);
                background-repeat: no-repeat;
                background-position: right .6em center;
            }

            & > nav {
                position: absolute;
                z-index: 9;
                top: 100%;
                left: 0;
                flex-direction: column;
                width: 100%;
                margin: 0;
                padding: 0;
                background-color: #FFF;
                border-bottom-right-radius: 3px;
                border-bottom-left-radius: 3px;
                box-sizing: border-box;

                opacity: 0;
                visibility: hidden;
                transition: all .15s ease-out;

                & > a {
                    align-self: stretch;
                    margin: 0;
                    padding:.4em .6em;
                    color: @color-gray-dark;
                    text-align: left;
                }
            }

            &.open {
                box-shadow: 0 0 29px rgba(0,0,0,.2);

                & > nav {
                    opacity: 1;
                    visibility: visible;
                }

                & > button {
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }

        & > .helper {
            display: none;
            //opacity: 0;
            //transition: opacity .2s ease-out;

            & > span {
                margin-top: 1rem;
            }
        }
    }

    .row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;

        & > div {
            width: 50%;
            margin: 0;
            padding: 15px;
            box-sizing: border-box;

            @media (max-width: 767px) {
                width: 100%;
            }

            &:not(:nth-last-child(2)):first-child {
                width: 100%;

                @media (min-width: 768px) {
                    & > .card {
                        & > .card-thumb > div {
                            padding-bottom: 40%;
                        }

                        & > .card-body {
                            h3 {
                                flex-direction: row;

                                span {
                                    margin-right: 1rem;
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    & > .categories {
        display: none;

        &.on {
            display: block;
        }

        & > div {
            display: none;
            @media (max-width: 400px) {
                //Temp
                //overflow-x: hidden;
            }

            & > h2 {
                margin: 1.2rem 0;
                text-align: left;

            }

            &#video > h2 {
                color: #FFF;
            }

            &#report > h2 {
                color: #455560;
            }

            &#document > h2 {
                color: #009CDB;
            }

            &#presentation > h2 {
                color: #CBD302;
            }
        }
    }

    &#data-wrapper[data-sortby="category"] {
        .helper {
            display: flex;
            //opacity: 1;
        }
    }

    &#data-wrapper[data-categories*="video"] {
        & > .categories > #video {
            display: block;
        }
    }

    &#data-wrapper[data-categories*="report"] {
        & > .categories > #report {
            display: block;
        }
    }

    &#data-wrapper[data-categories*="document"] {
        & > .categories > #document {
            display: block;
        }
    }

    &#data-wrapper[data-categories*="presentation"] {
        & > .categories > #presentation {
            display: block;
        }
    }

    &#data-wrapper:not([data-categories*="video"]) {
        & > .categories > #report > h2 {
            color: #FFF !important;
        }
    }

    &#data-wrapper:not([data-categories*="video"]):not([data-categories*="report"]) {
        & > .categories > #document > h2 {
            color: #FFF !important;
        }
    }

    &#data-wrapper:not([data-categories*="video"]):not([data-categories*="report"]):not([data-categories*="document"]) {
            & > .categories > #presentation > h2 {
                color: #FFF !important;
            }
        }

    .card {
        display: flex;
        flex-direction: column;
        height: 100%;
        text-align: left;
        transition: box-shadow .2s ease-out;

        &:hover {
            box-shadow: 0 0 29px rgba(0,0,0,.2);
        }

        &-thumb {
            & > div {
                position: relative;
                padding-bottom: 55%;
                overflow: hidden;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                box-shadow: inset 0 0 0 1px rgba(0,0,0,.15);

                .video {
                    & > video {
                        position: absolute;
                        z-index: 1;
                        top: 0;
                        left: 0;
                        width: 100%;
                    }

                    & > * {
                        display: none;
                    }
                }

                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background-repeat: no-repeat;
                    background-position: center;
                    transition: opacity .15s ease-out;
                }

                &[style^="background-image:"]:after,
                &:before {
                    opacity: 0;
                }
            }
        }

        &-body {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex-grow: 1;
            margin: 0;
            padding: 25px;
            border: 1px solid #dedede;
            border-top: none;
            background: #FFF;

            h3 {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin: 0 0 1rem;
                font-size: 2.25rem;
                font-weight: 300;

                span.cat {
                    display: block;
                    margin-bottom: 1rem;
                    padding: .5em;
                    font-size: 1rem;
                    font-weight: 500;
                    color: #FFF;
                    text-transform: uppercase;
                    letter-spacing: .06em;
                }
                // IE stuff
                div.name {
                    display: block;
                    width: 100%;
                    margin: 0;
                }
            }

            p {
                margin: 0;
                font-size: 16px;
            }

            .action {
                position: relative;
                align-self: flex-start;
                width: 100%;
                margin-top: 1.5rem;

                & > * {
                    margin: 0;
                    transition: all .15s ease-out;
                }

                & > .video {
                    display: none;
                }

                & > div {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: flex;
                    align-items: center;
                    opacity: 0;
                    visibility: hidden;



                    & > * {
                        margin: 0 1rem 0 0;
                        @media (max-width: 400px) {
                            margin: 0 0.5rem 1rem 0;
                            padding: 0.5em 1em;
                        }                         
                    }

                    &.variants > * {
                        text-transform: uppercase;
                    }
                }

               
                & > .variants,
                &.done > .button,
                &.done:hover > .button,
                &.language.done > .variants,
                &.language.done > .variants a,
                &.language.done:hover > .variants {
                    opacity: 0;
                    visibility: hidden;
                }
                
                &.done > .downloaded {
                    opacity: 1;
                    visibility: visible;
                }

                @media (max-width: 400px) {
                    &.language > a {position: absolute;}
                    .downloaded {display: none}
                    .variants {
                        position: static;
                        display: block;
                    }
                }                 
            }
        }

        .card-type (@color, @icon) {
            & > .card-thumb > div {
                background-color: @color;

                &:after {
                    background-image: url(@icon);
                    background-size: 120px;
                }
            }

            & > .card-body {
                h3 {
                    color: @color;

                    span.cat {
                        background-color: @color;
                    }
                }

                & > .action {
                    .button {
                        color: @color-gray-dark;
                        border-color: @color;

                        &:hover {
                            color: @color-white !important;
                            background: @color;
                        }
                    }

                    &.done > .downloaded {
                        .button {
                            color: @color-white;
                            background: @color;
                        }
                    }
                }
            }
            & > .card-thumb > div {
                &:after {
                    z-index: 3;
                    opacity: 1;
                }
            }
            &:hover {
                & > .card-thumb > div {
                    &:before {
                        z-index: 2;
                        opacity: 1;
                        background-image: -moz-linear-gradient(top, fade(@color, 0) 0%, fade(@color, 60%) 100%);
                        background-image: -webkit-linear-gradient(top, fade(@color, 0) 0%, fade(@color, 60%) 100%);
                        background-image: linear-gradient(to bottom, fade(@color, 0) 0%, fade(@color, 60%) 100%);
                    }

                }

                & > .card-body {
                    & > .action > .button {
                        color: @color;
                    }
                }
            }
        }

        &.video {
            .card-type(#DC006B, '/assets/icon-video.svg');
        }

        &.report {
            .card-type(#455560, '/assets/icon-report.svg');
        }

        &.presentation {
            .card-type(#CBD302, '/assets/icon-presentation.svg');
        }

        &.document {
            .card-type(#009CDB, '/assets/icon-document.svg');
        }
        &.languages:hover .language:not(.done) .button {
            opacity: 0;
            visibility: hidden;            
        }
        &.languages:hover .language:not(.done) .variants,
        &.languages:hover .language:not(.done) .variants a {
            opacity: 1 !important;
            visibility: visible !important;
        }

    }
}

.header-video {
    position: absolute;
    z-index: -100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;

    video {
        position: absolute;
        top: 50%;
        left: 50%;
        width: auto;
        height: auto;
        min-width: 100%;
        min-height: 100%;
        transform: translate(-50%, -50%);
    }

    @media (min-width: 768px) {
        & + header {
            background: linear-gradient(rgba(0,0,0,.65),rgba(0,0,0,.65)) !important;
        }
    }

    .on(@mobile, {
        display: none;
    });
}

#modal {
    //section {
    //    background: transparent; // For what? this broke other popups
    //}

    article {
        .video {
            display: block;
            height: 28vw;
            margin: -3em -2em;

            & > video,
            & > iframe {
                // position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
            .on(@tablet, {
                height: auto;
                & > video,
                & > iframe {min-height: 44vw;} 
            });
            .on(@mobile, {
                & > video,
                & > iframe {min-height: 50vw;} 
            });
        }
    }
}



.hub.executive-summaries {

    h2.h1 {
        margin: 1em 0 0;
        letter-spacing: .06em;

        & + button {
            margin: 2em 0;
        }
    }

    button.neutral {
        padding-top: .45em;
        padding-bottom: .45em;
        font-size: 100%;
        border-radius: 20px;
    }
}


body.resources,
body.data-analysis,
body.executive-summaries {

    nav.main {
        &:not(.open){
            a, .active, a:hover, a:visited {color: @color-black;}
            .brand {
                img.alt {display: none;}
                img {display: block;}
            }
            &.alt {
                background-color: transparent;
                img {display: none;}
                img.alt {display: block;}                
                a, .active, a:hover, a:visited {color: white;}        
                .hamburger:not(.is-active) {
                    > span > span {
                        background-color: @color-white;
                        &:before, &:after {
                            background-color: @color-white;
                        }
                    }
                }
            }
        }
        .brand > img {
            display: none;
            &.alt {
                display: block;
            }
        }
    }


    p {
        font-family: @font-family-structure;
    }
}

//endregion

//IE
_:-ms-fullscreen, :root .resources main > article.page-container .card > .card-thumb > div::after { background-size: 220px; } /* IE11 */